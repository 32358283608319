:root
  --background: #121212
  --card: #181818
  --bottomNav: #242424
  --text: white
  --text2: #707070
  --primary: #222222
  --secondary: #909090
  --skeleton: #1e1e1e
  --border: #505050
  --headerSize: 60px
  --bottomNavigationSize: 60px
  --logoSize: 44px
  --titleSize: 22px
  --textSize: 18px
  --brand: #9DDB2B
  --brand2: #76B743
  --brand3: #5f9f2e
  --link: #4c84f5
  --red: #db3b3b
  --yellow: #ffae00
*
  box-sizing: border-box
  -webkit-tap-highlight-color: transparent !important
  font-family: sans-serif

::-webkit-scrollbar
  width: 0
  height: 0

::-webkit-scrollbar-track
  background: var(--primary)

::-webkit-scrollbar-thumb
  background: var(--secondary)

::-moz-selection
  background: var(--brand2)
  color: var(--text)

::-webkit-selection
  background: var(--brand2)
  color: var(--text)

::selection
  background: var(--brand2)
  color: var(--text)

body
  color: white
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

@media not all and (min-resolution:.001dpcm)
  img[loading="lazy"]
    clip-path: inset(0.5px)

html, body
  background-color: var(--background)
  margin: 0
  padding: 0
  overflow: hidden
  position: relative
  width: 100%
  height: 100%

#__next
  height: 100%
  width: 100%

.container
  position: relative
  display: flex
  flex-flow: column
  justify-content: space-between
  align-items: stretch
  width: 100%
  height: 100%

@supports (font: -apple-system-body) and (-webkit-appearance: none)
  img[loading="lazy"]
    clip-path: inset(0.6px)

svg
  width: 24px
  height: 24px
  fill: transparent
  stroke: var(--text)
  transform: scale(1.35)

.loader 
  width: 30px
  height: 30px
  border-radius: 50%
  display: inline-block
  border-top: 2px solid #FFF
  border-right: 2px solid transparent
  box-sizing: border-box
  animation: rotation 0.5s linear infinite
  position: absolute
  z-index: 4


@keyframes rotation 
  0% 
    transform: rotate(0deg)
  
  100% 
    transform: rotate(360deg)