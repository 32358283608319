.bar
    width: 100%
    min-height: var(--bottomNavigationSize)
    height: var(--bottomNavigationSize)
    display: flex
    justify-content: space-between
    align-items: center
    background-color: var(--bottomNav)
    position: relative
    box-shadow: 0 -5px 10px rgba(0,0,0,0.15)
    padding: 0 24px
    .btn
        all: unset
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        position: relative
        min-width: 24px
        width: 24px
        min-height: var(--bottomNavigationSize)
        height: var(--bottomNavigationSize)
        .searchIcon
            min-width: 24px
            min-height: 24px
            width: 24px
            height: 24px
            pointer-events: none
            position: absolute
        svg
            min-width: 24px
            min-height: 24px
            width: 24px
            height: 24px
            stroke: var(--text2)
            fill: none
            pointer-events: none
            position: absolute
            transform: scale(1.35)
        .search
            width: 100%
            min-height: 100%
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            z-index: 1
            background-color: var(--bottomNav)
            position: relative
            input
                width: 100%
                height: 100%
                background-color: transparent
                border: none
                outline: none
                font-size: var(--textSize)
                color: white
                cursor: pointer
                caret-color: transparent
                padding: 0 30px
            input::placeholder
                opacity: 0
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus
                -webkit-text-fill-color: var(--text)
                -webkit-box-shadow: 0 0 0px 1000px var(--primary) inset
    .activeLink
        svg
            stroke: var(--text)
.showSearchBar
    .searchBtn
        border: none
        border-radius: 0
        min-width: 100%
        min-height: 100%
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        z-index: 1
        left: 50%
        transform: translateX(-50%)
        top: 0
        overflow: hidden
        .search
            border-radius: 0
            position: relative
            input
                width: 100%
                height: 100%
                color: white
                cursor: auto
                caret-color: white
                padding: 0 64px 0 24px
            input::placeholder
                opacity: 1
            .searchIcon
                min-width: 60px
                min-height: 60px
                width: 60px
                height: 60px
                pointer-events: all
                position: absolute
                right: 0
                display: flex
                justify-content: center
                align-items: center
                cursor: pointer