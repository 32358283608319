.btn 
  all: unset
  pointer-events: all
  position: relative
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  padding: 16px 36px
  cursor: pointer
  background: var(--link)
  color: var(--text)
  border: none
  outline: none
  font-size: 16px
  font-weight: 400
  box-sizing: border-box
  border-radius: 5px 5px
  text-transform: capitalize
  .content
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    z-index: 4
    width: 100%
    svg
      position: absolute
      right: 12px
      stroke: var(--border)
      pointer-events: none
  .google
    svg
      stroke: none
  .text
    font-size: inherit
    font-weight: inherit
    pointer-events: none
  .backgroundText
    font-size: inherit
    font-weight: inherit
    color: transparent
    pointer-events: none
  span
    position: absolute
    display: block
    width: 0
    height: 0
    border-radius: 50%
    background-color: var(--brand2)
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out
    transform: translate(-50%, -50%)
    z-index: 1
    font-size: inherit
    font-weight: inherit
    pointer-events: none

.btn:hover
  .content
    svg
      stroke: white
  .google
    svg
      stroke: none
  span
    width: 300%
    height: 1800%
    stroke: var(--text)

.ripple
  position: absolute
  border-radius: 50%
  width: 1px
  height: 1px
  transform: scale(0)
  animation: ripple 0.2s linear
  background-color: rgba(255, 255, 255, 0.25)
  z-index: 3
  opacity: 1
  pointer-events: none

@keyframes ripple
  to
    transform: scale(1.5)
    opacity: 0

.link
  padding: 24px 12px
  padding-right: 36px
  border-radius: 0
  .text
    color: var(--link)
    font-size: 24px
    text-transform: lowercase
    width: calc( 100% - 60px )
    word-break: break-all
  .content
    justify-content: flex-start
    svg
      right: 18px

.loader 
  width: 30px
  height: 30px
  border-radius: 50%
  display: inline-block
  border-top: 2px solid #FFF
  border-right: 2px solid transparent
  box-sizing: border-box
  animation: rotation 0.5s linear infinite
  position: absolute
  z-index: 4


@keyframes rotation 
  0% 
    transform: rotate(0deg)
  
  100% 
    transform: rotate(360deg)