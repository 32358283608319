.search
  width: 100%
  display: flex
  align-items: center
  background-color: var(--card)
  position: relative
  min-height: 60px
  height: 60px
  box-shadow: 0 0px 30px rgba(0,0,0,0.35)
  input
    all: unset
    width: calc( 100% - 60px - 12px )
    padding-left: 12px
    color: var(--text)
  input::-ms-clear
    display: none
    width : 0
    height: 0
  input::-ms-reveal
    display: none
    width : 0
    height: 0
  input[type="search"]::-webkit-search-decoration,input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-results-button,input[type="search"]::-webkit-search-results-decoration
    display: none
  button
    padding: 0
    min-width: 81px
    min-height: 100%
    position: absolute
    right: 0
    border-radius: 0
    svg
      position: static !important
      transform: scale(1.35) translateX(1px) !important