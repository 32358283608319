.header
    display: flex
    justify-content: center
    align-items: center
    min-height: var(--headerSize)
    height: var(--headerSize)
    width: 100%
    border-bottom: 1px solid var(--border)
    box-shadow: 0 5px 10px rgba(0,0,0,0.15)
    background-color: var(--background)
    position: relative

    a
        all: unset
        display: flex
        align-items: center
        cursor: pointer
        .logo
            width: var(--logoSize)
            height: var(--logoSize)
            margin-right: 6px
            position: relative
            pointer-events: none
        span
            font-size: var(--titleSize)

    .backBtn
        position: absolute
        left: 0
        top: 0
        background-color: var(--bottomNav)
        height: 100%
        display: flex
        align-items: center
        justify-items: center
        padding: 0 24px
        cursor: pointer
        svg
            stroke: transparent
            isolation: isolate
            fill: var(--text2)